<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary">
          Membership
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        md="12"
        class="p-lg-5"
      >
        <div
          class="brand-img"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="6"
        md="12"
        class="d-flex align-items-center auth-bg px-lg-3 px-md-0 px-sm-0 p-lg-5 p-md-0 p-sm-0"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-5 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to PAVRISK! 👋
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email or Phone Number"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    autofocus
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid || isLoading"
              >
                {{ !isLoading ? "Sign in" : "Please wait..." }}
                <b-spinner
                  v-if="isLoading"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>Create an account</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import {
  BCol,
  BRow,
  BImg,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BCol,
    BRow,
    BImg,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      isLoading: false,
      // validation rules
      required,
      email,

      // sideImg: require('@/assets/images/pages/login-v2-dark.svg'),
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/pavrisk-logo.png'),
      home_route: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/pavrisk-logo.png')
        // this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then(response => {
              this.$router.replace({ name: 'auth-two-factor-authentication', params: { id: response.data.tracking_number } })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${response.data.name}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                    },
                  })
                })
              // const {
              //   member,
              //   token,
              //   permissions,
              //   notifications,
              // } = response.data

              // useJwt.setToken(token)
              // localStorage.setItem('user', JSON.stringify(member))
              // localStorage.setItem('permissions', JSON.stringify(permissions))
              // const abilities = []
              // permissions.forEach(ability => {
              //   abilities.push({
              //     action: ability.split(' ')[1],
              //     subject: ability.split(' ')[0],
              //   })
              // })
              // this.$ability.update(abilities)
              // if (notifications.length) {
              //   const data = notifications[0]
              //   this.$router.replace({ name: data.link })
              //   this.$toast({
              //     component: ToastificationContent,
              //     props: {
              //       title: data.message,
              //       icon: 'CoffeeIcon',
              //       variant: 'success',
              //     },
              //   })
              // } else {
              //   this.$router.replace(getHomeRouteForLoggedInUser(permissions[0]))
              //   this.$toast({
              //     component: ToastificationContent,
              //     position: 'top-right',
              //     props: {
              //       title: 'Login Successful!',
              //       icon: 'CoffeeIcon',
              //       variant: 'success',
              //     },
              //   })
              // }
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Invalid credentials',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.brand-img img {
  width: 30%;
  margin: 190px 0 0 80px;
}

@media only screen and (min-width: 998px) {
  .brand-img img {
    position: fixed;
  }
}
@media only screen and (max-width: 998px) {
  .brand-logo {
    display: none;
  }
  .brand-img {
    position: relative;
    margin-left: 240px;
    margin-top: -50px;
  }
  .brand-img img {
    width: 100%;
    margin: 100px 0 0 -120px;
  }
  .auth-bg {
    height: 400px;
    margin-top: -250px;
  }
}

@media only screen and (max-width: 570px) {
  .brand-logo {
    display: none;
  }
  .brand-img {
    position: relative;
    margin-left: 100px;
    margin-top: -50px;
  }
  .brand-img img {
    width: 100%;
    margin: 100px 0 0 -50px;
  }
}
</style>
